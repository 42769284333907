import React, { useMemo } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart as _LineChart,
  LineProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
} from 'recharts';
import { CustomGraphTooltipContent } from 'templates/SecurityAssessments/components/CustomGraphTooltipContent/assets';

import { formatMillionsValue } from '../Money';
import { ChartContainer, Props as ChartContainerProps } from './ChartContainer';

export interface RiskLineDataPoint {
  name: string;
  score: number;
  badScore?: number;
  distribution?: Array<{
    name: string;
    value: number;
    previous: number;
    opposite?: boolean;
  }>;
}

export interface Props extends ChartContainerProps {
  data: RiskLineDataPoint[];
  dollars?: boolean;
  skipValues?: Array<number>;
  yAxisOverrides?: {
    left?: Partial<YAxisProps>;
    right?: Partial<YAxisProps>;
  };
  yAxisId?: {
    score?: 'left' | 'right';
  };
  showTooltip?: boolean;
}

// Keep outside of component to avoid re-rendering
const mockScore = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];

export const RiskLine = (props: Props) => {
  const hasAnyRightAxis = Object.values(props.yAxisId || {}).includes('right');
  const commonLineProps: Omit<LineProps, 'dataKey' | 'ref'> = {
    radius: 4,
    strokeWidth: 1,
    type: 'monotone',
  };

  const commonYAxisProps: YAxisProps = {
    fontSize: 10,
    allowDecimals: false,
    tickFormatter: (tick: number) => {
      const val = tick / 1000;
      let formatted = tick.toLocaleString();

      if (tick >= 1000) {
        formatted = `${parseFloat(val.toFixed(1))}k`;
      }

      if (tick >= 1_000_000) {
        formatted = formatMillionsValue(tick);
      }

      return props.dollars ? `$${formatted}` : formatted;
    },
  };

  const leftYAxisProps: YAxisProps = {
    ...(hasAnyRightAxis ? { yAxisId: 'left', orientation: 'left' } : {}),
    ...commonYAxisProps,
    ...props.yAxisOverrides?.left,
  };

  const rightYAxisProps: YAxisProps = {
    ...(hasAnyRightAxis ? { yAxisId: 'right', orientation: 'right' } : {}),
    ...commonYAxisProps,
    ...props.yAxisOverrides?.right,
  };

  const parsedData = useMemo(() => {
    if (props.locked) {
      // Randomize the data
      return props.data.map((d, idx) => ({
        ...d,
        score: idx % 2 === 0 ? mockScore[idx % 3] : mockScore[(idx + 1) % 3],
      }));
    } else {
      return props.data;
    }
  }, [props.data, props.locked]);

  return (
    <ChartContainer {...props}>
      <ResponsiveContainer>
        <_LineChart width={900} height={400} data={parsedData} margin={{ top: 20, bottom: 20, right: 20 }}>
          <CartesianGrid strokeDasharray='4 2' vertical={false} />
          <XAxis dataKey='name' fontSize={10} />

          <YAxis {...leftYAxisProps} />
          {hasAnyRightAxis && <YAxis {...rightYAxisProps} />}

          {props.showTooltip && (
            <Tooltip
              contentStyle={{ borderRadius: 24, border: '1px solid #EDEDED', padding: '16px 20px' }}
              content={<CustomGraphTooltipContent />}
              filterNull={false}
            />
          )}

          <Line
            isAnimationActive={false}
            dataKey='score'
            fill='rgb(164, 213, 195)'
            stroke='#4EAE8B'
            yAxisId={props.yAxisId?.score || (hasAnyRightAxis ? 'left' : undefined)}
            {...commonLineProps}
          />

          <Line
            isAnimationActive={false}
            dataKey='badScore'
            fill='rgb(243, 176, 174)'
            stroke='#ED6660'
            yAxisId={props.yAxisId?.score || (hasAnyRightAxis ? 'left' : undefined)}
            {...commonLineProps}
          />
        </_LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};
