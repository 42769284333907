import { RuleEffect } from '@jsonforms/core';
import { JsonSchema } from '@jsonforms/core';
import { AgentDTO } from 'dtos/agent';
import { ClientCategory, ClientStatus, CompanyDTO } from 'dtos/company';
import { DeepScanDTO, EncryptionStatus, NmapScanStats, PiiScanSummary, ReportData } from 'dtos/deep-scan';
import { PolicyDTO } from 'dtos/policy';

import { SecurityScanType } from './security-finding';

// Read more on statuses here: https://docs.google.com/document/d/17oIIbFPvF_RYjbc3nU9qvcjM9ec68TcpVL1xIFdjT2w/edit#
export enum ApplicationStatus {
  NOT_STARTED = 'not_started',
  CONFIGURATION_PENDING = 'configuration_pending',
  CONFIGURATION_DONE = 'configuration_done',
  DATA_PENDING = 'data_pending',
  REPORT_REQUESTED = 'report_requested',
  IN_PROGRESS = 'in_progress',
  REPORT_COMPLETED = 'report_completed',
  RESCAN_ALL = 'rescan_all',
  UNINSTALL_ALL = 'uninstall_all',
  IN_REVIEW = 'in_review',
  SUBMITTED = 'submitted',
  REQUEST_FOR_QUOTE = 'request_for_quote',
  QUOTES_DO_NOT_EXIST = 'quotes_do_not_exist',
  QUOTED = 'quoted',
  BIND_REQUESTED = 'bind_requested',
  BOUND = 'bound',
  ARCHIVED = 'archived',
}

export enum ScanApplicationStatus {
  CONFIGURATION_PENDING = 'configuration_pending',
  CONFIGURATION_DONE = 'configuration_done',
  DATA_PENDING = 'data_pending',
  IN_PROGRESS = 'in_progress',
  REPORT_REQUESTED = 'report_requested',
  REPORT_COMPLETED = 'report_completed',
  RESCAN_ALL = 'rescan_all',
  UNINSTALL_ALL = 'uninstall_all',
  ARCHIVED = 'archived',
}

export enum InsuranceApplicationStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  IN_REVIEW = 'in_review',
  SUBMITTED = 'submitted',
  REQUEST_FOR_QUOTE = 'request_for_quote',
  QUOTES_DO_NOT_EXIST = 'quotes_do_not_exist',
  QUOTED = 'quoted',
  BIND_REQUESTED = 'bind_requested',
  BOUND = 'bound',
}

export enum ApplicationDocumentType {
  // Risk Assessment Plus
  TELIVY_COMPLETE_REPORT_PDF = 'telivy_complete_report_pdf',
  TELIVY_COMPLETE_REPORT_DOCX = 'telivy_complete_report_docx',
  TELIVY_EXTERNAL_SCAN_REPORT = 'telivy_external_scan_report',
  TELIVY_PASSWORDS_REPORT = 'telivy_passwords_report',
  TELIVY_PASSWORDS_REUSE_REPORT = 'telivy_passwords_reuse_report',
  TELIVY_DARK_WEB_REPORT = 'telivy_dark_web_report',
  TELIVY_NETWORK_INVENTORY_REPORT = 'telivy_network_inventory_report',

  NODEWARE_EXECUTIVE_REPORT = 'nodeware_executive_report',
  NODEWARE_NETWORK_REPORT = 'nodeware_network_report',
  NODEWARE_TOP_VULNERABILITIES_REPORT = 'nodeware_top_vulnerabilities_report',
  TELIVY_VULNERABILITIES_REPORT = 'telivy_vulnerabilities_report',

  AUGUMENT_DETAILED_REPORT = 'augument_detailed_report',
  AUGMENTT_OVERVIEW_REPORT = 'augmentt_overview_report',

  ACTIFILE_SENSITIVE_DOCUMENTS = 'actifile_sensitive_documents',
  ACTIFILE_DETAILED_RISK = 'actifile_detailed_risk',

  M365_SECURITY_REPORT = 'm365_security_report',
  M365_SECURITY_DATA_REPORT = 'm365_security_data_report',
  GWS_SECURITY_REPORT = 'gws_security_report',
  GWS_SECURITY_DATA_REPORT = 'gws_security_data_report',

  SAAS_ALERTS_DETAILED_REPORT = 'saas_alerts_detailed_report',
  SAAS_ALERTS_SUMMARY_REPORT = 'saas_alerts_summary_report',
  PHISHING_REPORT = 'phishing_report',

  TELIVY_ALL_REPORTS_ZIP = 'telivy_all_reports_zip',

  // deprecated
  TELIVY_COMPLETE_REPORT = 'telivy_complete_report', // deprecated, use TELIVY_COMPLETE_REPORT_PDF instead
  AUGUMENT_REPORT = 'augument_report', // deprecated, use AUGMENTT_OVERVIEW_REPORT instead
  PII_EXCEL_REPORT_BUNDLE = 'pii_excel_report_bundle',

  // external assessment
  BENCHMARK = 'benchmark',
  SECURITY_REPORT = 'security_report',
  RISK_CALCULATOR = 'risk_calculator',
  EMPLOYEE_LIST = 'employee_list',
}

export enum ApplicationApiQuotingStatus {
  FETCHED = 'FETCHED',
  FETCHING_PENDING = 'FETCHING_PENDING',
  FETCHING = 'FETCHING',
}

export interface ApplicationQuotesCount {
  activeCount: number;
  deactivatedCount: number;
  failedCount: number;
  inReviewCount: number;
}

export interface ApplicationDocumentDTO {
  id: string;
  documentPath: string;
  documentType: ApplicationDocumentType;
  documentMetadata: string | null;
  fileName: string;
  createdAt: Date;
  groupId: string | null;
}

export interface ProposalVersionDTO {
  id: string;
  proposalVersion: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface UISchemaFixedType {
  type: string;
  text?: string;
  scope?: string;
  label?: string;
  placeholder?: string;
  options?: Record<string, unknown>;
  rule?: {
    effect: RuleEffect;
    condition: {
      scope: string;
      schema: JsonSchema;
    };
  };
  elements?: UISchemaFixedType[];
  propertiesPaths?: string[];
}

export interface UiSectionSchema {
  label: string;
  pageSlug: string;
  questionsCount: number;
  progress: number; // progress percent to show on progressbar
  uiSchema: UISchemaFixedType;
  helperBox: {
    title: string;
    description: string;
  };
}

export interface UiSchema {
  sections: UiSectionSchema[];
}

export interface ApplicationVersionDTO {
  id: string;
  dataSchema: JsonSchema;
  uiSchema: UiSchema;
  applicationVersion: string;
  applicationTemplatePath: string;
  createdAt: Date;
  updatedAt: Date;
  proposalVersion: ProposalVersionDTO;
}

export interface DnBMatch {
  companyName: string;
  streetAddressLine1: string;
  streetAddressLine2: string | null;
  city: string;
  state: string;
  zipCode: string;
  duns: string;
}

export interface ApplicationBreachDataDTO {
  id: string;
  securityScanId: string;
  domainName: string;
  sourceId: string;
  breachName: string | null;
  lastBreach: string | null;
  email: string;
  ipAddress: string | null;
  userName: string | null;
  password: string;
  hashedPassword: string | null;
  hashType: string | null;
  name: string | null;
  address: string | null;
  phone: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export enum SecurityBenchmarkingWeight {
  firewall = 'firewall',
  antiVirus = 'antiVirus',
  backup = 'backup',
  recovery = 'recovery',
  mfa = 'mfa',
  encryption = 'encryption',
  patching = 'patching',
  continuity = 'continuity',
  mediaContent = 'mediaContent',
  vendorSecurityControl = 'vendorSecurityControl',
  foundTransferControl = 'foundTransferControl',
  securityTraining = 'securityTraining',
}

export enum Impact {
  INFO = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export interface BusinessScoreItem {
  type: SecurityBenchmarkingWeight;
  impact: Impact;
}

interface BusinessScore {
  total: number;
  items: BusinessScoreItem[];
}

export interface InsurabilityScoreComponents {
  score?: number;
  controlsCount?: number;
  controlsTotal?: number;
  carrier?: string;
}
export interface InsurabilityBusinessScore {
  total: number;
  insurabilityScoreComponents?: Array<InsurabilityScoreComponents>;
  eligibleMarketPercentage: number;
  quotableMarketPercentage: number;
  allMarketsNumber?: number;
  eligibleMarketsNumber?: number;
  quotableMarketsNumber?: number;
  items: Array<BusinessScoreItem>;
}

export interface ApplicationSecurityStats {
  preview: boolean;
  ransomwareCost: number;
  businessInterruptionCost: number;
  securityScore: {
    industryAverage: number;
    businessScore: BusinessScore;
  };
  insurabilityScore: {
    industryAverage?: number;
    businessScore: InsurabilityBusinessScore;
  };
  dataBreachItems: Array<{
    name: string;
    cost: number;
  }>;
  applicationStatus: ApplicationStatus;
  updatedAt: string;
}

export interface DnbMatchQuery {
  name: string;
  streetAddressLine1?: string;
  streetAddressLine2?: string;
  postalCode?: string;
  url?: string;
  email?: string;
  telephoneNumber?: string;
}

interface DnbMatchAddress {
  addressCountry: {
    isoAlpha2Code: string;
    name: string;
  };
  addressLocality: {
    name: string;
  };
  addressRegion: {
    name: null | string;
    abbreviatedName: string;
  };
  postalCode: string;
  postalCodeExtension?: string;
  streetAddress: {
    line1: string;
    line2: string | null;
  };
}

interface DnbMatchOrganization {
  duns: string;
  dunsControlStatus: {
    operatingStatus: {
      description: string;
      dnbCode: number;
    };
    isMailUndeliverable: boolean;
  };
  primaryName: string;
  websiteAddress: Array<{
    url: string;
    domainName: string;
  }>;
  tradeStyleNames: Array<{
    name: string;
    priority: number;
  }>;
  telephone: Array<{
    telephoneNumber: string;
    isUnreachable: boolean;
  }>;
  primaryAddress: DnbMatchAddress;
  mailingAddress: DnbMatchAddress;
  registrationNumbers: Array<{
    registrationNumber: string;
    typeDescription: string;
    typeDnBCode: number;
  }>;
  mostSeniorPrincipals: Array<{
    fullName: string;
  }>;
  isStandalone: boolean;
  corporateLinkage: {
    familytreeRolesPlayed: Array<{
      description: string;
      dnbCode: number;
    }>;
  };
}

interface DnbMatchCandidate {
  displaySequence: number;
  organization: DnbMatchOrganization;
  matchQualityInformation: {
    confidenceCode: number;
    matchDataProfile: string;
    nameMatchScore: number;
    matchDataProfileComponentsCount: number;
    matchGrade: string;
    matchGradeComponentsCount: number;
    matchGradeComponents: Array<{
      componentType: string;
      componentRating: string;
    }>;
    matchDataProfileComponents: Array<{
      componentType: string;
      componentValue: string;
    }>;
  };
}

interface DnbTransactionDetails {
  transactionID: string;
  transactionTimestamp: string;
}

export interface DnbMatchResponse {
  matchCandidates: DnbMatchCandidate[];
  candidatesMatchedQuantity: number;
  matchDataCriteria: string;
  transactionDetail: DnbTransactionDetails;
}
export interface DnbDunsAddress extends DnbMatchAddress {
  latitude: number;
  longitude: number;
  minorTownName: null;
  continentalRegion: {
    name: string;
  };
  addressCounty: {
    name: string;
  };
}

interface DnbDunsOrganization {
  duns: string;
  isFortune1000Listed: boolean | null;
  isForbesLargestPrivateCompaniesListed: boolean | null;
  industryCodes: Array<{
    code: string;
    description: string;
    typeDescription: string;
    typeDnBCode: number;
    priority: number;
  }>;
  isNonClassifiedEstablishment: boolean;
  registrationNumbers: Array<{
    registrationNumber: string;
    typeDescription: string;
    typeDnBCode: number;
    isPreferredRegistrationNumber: boolean;
  }>;
  primaryIndustryCode: {
    usSicV4: string;
    usSicV4Description: string;
  };
  primaryName: string;
  startDate: string;
  controlOwnershipDate: string;
  isStandalone: boolean;
  businessEntityType: {
    description: string;
    dnbCode: number;
  };
  isSmallBusiness: boolean | null;
  defaultCurrency: string;
  email: Array<{
    address: string;
  }>;
  websiteAddress: Array<{
    url: string;
    domainName: string;
  }>;
  primaryAddress: DnbDunsAddress;
  numberOfEmployees: Array<{
    value: number;
    employeeFiguresDate: string;
    informationScopeDescription: string;
    informationScopeDnBCode: number;
    reliabilityDescription: string; // Like: 'Modelled'
    reliabilityDnBCode: number;
  }>;
  financials: Array<{
    financialStatementToDate: string;
    financialStatementDuration: null;
    informationScopeDescription: string;
    informationScopeDnBCode: number;
    reliabilityDescription: string; // Like: 'Modelled'
    reliabilityDnBCode: number;
    unitCode: string;
    yearlyRevenue: Array<{
      value: number;
      currency: string;
    }>;
  }>;
}

export interface DnbDunsResponse {
  transactionDetail: DnbTransactionDetails;
  organization: DnbDunsOrganization;
  inquiryDetail: {
    duns: string;
    blockIDs: string[];
  };
  blockStatus: Array<{
    blockID: string;
    status: string;
    reason: string | null;
  }>;
}

export interface ParsedDnbDunsData {
  companyName?: string;
  website?: string;
  street?: string;
  unit?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  yearEstablished?: number;
  annualRevenue?: number;
  employeeCount?: number;
  organizationType?: string;
  primaryOperations?: string;
  primaryOperationsLong?: string;
}

export interface ApplicationRawDnbDTO {
  id: string;
  dnbDone: boolean;
  rawMatchResponse: DnbMatchResponse | null | { error: string };
  matchRequestData: DnbMatchQuery;
  matchResponse: DnBMatch[] | null;
  selectedDuns: string | null;
  rawDunsResponse: DnbDunsResponse | null;
  parsedData: ParsedDnbDunsData | null;
  createdAt: Date;
  application: ApplicationDTO;
  applicationId: string;
}

export interface DnbDataDTO {
  dnbDone: boolean;
  createdAt: string;
  selectedDuns: string | null;
  matchResponse: DnBMatch[] | null;
}

export interface ApplicationDTO {
  id: string;
  applicationNumber: number;
  applicationResponse: any; // FIXME
  applicationStatus: ApplicationStatus;
  applicationVersion: ApplicationVersionDTO;
  applicationVersionId?: string;
  billingEnabled: boolean;
  isDeepScan: boolean;
  isLightScan: boolean;
  deepScanId: string | null;
  __deepScan__?: DeepScanDTO | null;
  apiQuotingStatus: ApplicationApiQuotingStatus;
  createdAt: string;
  updatedAt: string;
  utmSource: string | null;
  dnbData: DnbDataDTO | null;
  origin: ApplicationOrigin;
  insuranceLine: ApplicationInsuranceLine;
  policy?: PolicyDTO;
  emailEnabled: boolean;
  securityScan?: SecurityScanDTO;
  monitoringEnabled: boolean;
  monitoringFrequency?: MonitoringFrequency;
  pentestRequested: boolean;
  securityOverallScore?: number | null;
  enableDarkWebFullPassword: boolean;
  companyId: string | null;
  company: CompanyDTO;
}

export enum MonitoringFrequency {
  QUARTERLY = 'QUARTERLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export interface UpdateApplicationSettingDTO {
  billingEnabled?: boolean;
  emailEnabled?: boolean;
  monitoringEnabled?: boolean;
  monitoringFrequency?: MonitoringFrequency;
  pentestRequested?: boolean;
  enableDarkWebFullPassword?: boolean;
}

export interface CreateApplicationDTO {
  organizationName: string;
  domain?: string;
  country?: string;
  maskPii?: boolean;
  clientCategory?: ClientCategory | null;
  clientStatus?: ClientStatus | null;
  antiVirus?: string | null;
  notifyViaEmail?: boolean;
  isDeepScan?: boolean;
  isLightScan: boolean;
  agentId?: string;
}

export enum ApplicationOrigin {
  AGENT_PORTAL = 'AGENT_PORTAL',
  EMBEDDED = 'EMBEDDED',
  GLOBAL_ADMIN = 'GLOBAL_ADMIN',
  BULK = 'BULK',
  TEST = 'TEST',
  STRIPE = 'STRIPE',
  IMPORTED = 'IMPORTED',
}

export const MAP_ORIGIN_TO_LABEL: { [key in ApplicationOrigin]: string } = {
  [ApplicationOrigin.AGENT_PORTAL]: 'Portal',
  [ApplicationOrigin.EMBEDDED]: 'Lead Magnet',
  [ApplicationOrigin.GLOBAL_ADMIN]: 'Global Admin',
  [ApplicationOrigin.BULK]: 'Bulk',
  [ApplicationOrigin.IMPORTED]: 'Import CSV',
  [ApplicationOrigin.TEST]: 'Test',
  [ApplicationOrigin.STRIPE]: 'Stripe',
};

export enum ApplicationInsuranceLine {
  CYBER = 'Cyber',
  TECH_EO = 'Tech Errors & Omissions',
  GENERAL = 'General Liability',
}

export interface ApplicationWithAgentDTO extends ApplicationDTO {
  agent: AgentDTO;
}

export interface ApplicationsResponse {
  message: string;
  applications: ApplicationDTO[];
  applicationsCount: number;
}

export interface ApplicationsAdminResponse {
  message: string;
  applications: ApplicationWithAgentDTO[];
  applicationsCount: number;
}

export interface ApplicationByIdResponse {
  message: string;
  application: ApplicationWithAgentDTO;
}

export enum ApplicationSortBy {
  ORGANIZATION_NAME = 'organization_name',
  STATUS = 'applicationStatus',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  TARGET_EFFECTIVE_DATE = 'target_effective_date',
  BROKER = 'email',
}

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum ApplicationInsuranceLineQueryOptions {
  CYBER = 'Cyber',
  TECH_EO = 'Tech_EO',
  GENERAL = 'General',
  SECURITY = 'Security',
  SECURITY_LIGHT_SCAN = 'Security (light scan)',
  SECURITY_DEEP_SCAN = 'Security (deep scan)',
}

export interface ApplicationsQuery {
  q?: string;
  status?: ApplicationStatus;
  origin?: ApplicationOrigin;
  sortBy?: ApplicationSortBy;
  sortOrder?: SortOrder;
  offset?: number;
  limit?: number;
  showBulk?: boolean;
  showMonitored?: boolean;
  effectiveDateDays?: number;
  creationDateDays?: number;
  insuranceLine?: ApplicationInsuranceLineQueryOptions;
  agencyId?: string;
  agentId?: string;
}

export interface CreateApplicationDocumentDTO {
  documentPath: string;
  fileName: string;
  documentType: ApplicationDocumentType;
  documentMetadata?: string;
}

export interface GenerateApplicationDocumentDTO {
  documentType: ApplicationDocumentType;
  documentMetadata?: string;
}

export interface ApplicationScanStats {
  riskyApps: {
    info: number;
    na: number;
    low: number;
    medium: number;
    high: number;
  };
}

export interface PasswordScanStats {
  passwordStrength: {
    na: number;
    weak: number;
    moderate: number;
    strong: number;
  };
}

export interface UserScanStatus {
  get_install_keys_at: Date | null;
  version: string | null;
  run_as_admin: string | null;

  installed_browsers: string[] | null;
  total_passwords_found: number | null;
  execution_error: string | null;

  scan_applications_total_found?: number;
  scan_applications_installed_applications_found?: number;
  scan_applications_error?: string | null;

  scan_apps_os_version?: string | null;
  scan_apps_product_name?: string | null;
  scan_apps_display_version?: string | null;

  scan_pii_error?: string | null;
  scan_pii_total_found?: number;
  scan_pii_started?: string | null;
  scan_pii_dryrun_files?: number;
}

export interface SystemScanStatus {
  get_install_keys_at: Date | null;
  version: string | null;
  run_as_admin: string | null;

  scan_applications_total_found?: number;
  scan_applications_installed_applications_found?: number;
  scan_applications_error?: string | null;

  scan_apps_os_version?: string | null;
  scan_apps_product_name?: string | null;
  scan_apps_display_version?: string | null;

  scan_pii_error?: string | null;
  scan_pii_total_found?: number;
  scan_pii_started?: string | null;
  scan_pii_dryrun_files?: number;

  scan_network_hosts_found?: number;
  scan_network_error?: string | null;

  uninstall_scheduled?: string | null;
  uninstall_all_date?: string | null;

  rescan_scheduled?: string | null;

  encryption_status?: EncryptionStatus[] | null;
}

export interface ScanStatus {
  system: SystemScanStatus;
  users: Record<string, UserScanStatus>;
  telivyAgentInstalledAt: Date | null;
  browserPasswordExecutedAt: Date | null;
  lastHeartBeatAt: Date | null;
}

export interface MonitoringSnapshotDTO {
  id: string;
  applicationId: string;
  createdAt: Date;
  updatedAt: Date;
  hostnames: string[];
  reportData: ReportData;
  scanStatus: Record<string, ScanStatus>;
  piiScanStats: Record<string, PiiScanSummary>;
  nmapScanStats: Record<string, NmapScanStats>;
  appsScanStats: ApplicationScanStats;
  passwordScanStats: Record<string, PasswordScanStats>;
  darkWebScanFindings: Record<string, number>;
  isLive: boolean;
}

export enum SecurityGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  NA = 'NA',
}

export enum HistoryResult {
  Good = 'Good',
  Average = 'Average',
  Low = 'Low',
  Poor = 'Poor',
}

export enum FindingSeverity {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export interface FindingValue {
  domainName: string;
  value: string;
}

export interface Finding {
  id: string;
  severity: FindingSeverity;
  name: string;
  scanType: SecurityScanType;
  values: FindingValue[];
  description?: string;
  risk?: string;
  recommendation?: string;
  references?: string[];
  grade?: SecurityGrade;
  eligibility?: number;
}

export interface SecurityScanResult {
  grade: SecurityGrade;
  scannedSubdomains?: string[];
  highSeverity: Finding[];
  mediumSeverity: Finding[];
  lowSeverity: Finding[];
  informationalSignals?: Finding[];
}

export interface BreachesScanResult {
  grade: SecurityGrade;
  highSeverity: Finding[];
  mediumSeverity: Finding[];
  lowSeverity: Finding[];
  listOfBreaches: Array<string>;
  numberOfBreaches: number;
}

export interface ScanEmailResponseDTO {
  socialEngineering: SecurityScanResult;
  breaches: BreachesScanResult;
}

export interface ScanDnsResponseDTO {
  dnsHealth: SecurityScanResult;
}

export interface ScanIpReputationResponseDTO {
  ipReputation: SecurityScanResult;
}

export interface ScanNetworkResponseDTO {
  networkScan: SecurityScanResult;
  endpointSecurity: SecurityScanResult;
  applicationSecurity: SecurityScanResult;
}

export interface PatchingCadenceDTO {
  patchingCadence: SecurityScanResult;
}

export interface InsurabilityDTO {
  insurability: SecurityScanResult;
}

export enum SecurityScanStatus {
  NEW = 'new',
  SCANNING = 'scanning',
  COMPLETED = 'completed',
}

export type ModuleBenchmarks = {
  [key in SecurityScanType]: number;
};

export interface BenchmarkData {
  sampleSize: number;
  securityOverallScore: number;
  securityScore: number;
  moduleBenchmarks: ModuleBenchmarks;
}

export interface Benchmark {
  id: string;
  naicsCode: string;
  revenueGroup: string;
  data: BenchmarkData;
  createdAt: Date;
  updatedAt: Date;
}

export interface SecurityScanDTO {
  id: string;
  securityStats: ApplicationSecurityStats;
  indication: number;
  // emailScan: ScanEmailResponseDTO;
  emailScannedAt: Date | null;
  // dnsScan: ScanDnsResponseDTO;
  dnsScannedAt: Date | null;
  // ipReputationScan: ScanIpReputationResponseDTO;
  ipReputationScannedAt: Date | null;
  // networkScan: ScanNetworkResponseDTO;
  networkScannedAt: Date | null;
  // patchingCadence: PatchingCadenceDTO;
  patchingCadenceScannedAt: Date | null;
  insurabilityScan: InsurabilityDTO;
  targetType: TargetType;
  status: SecurityScanStatus;
  domain: string;
  subDomains: string[] | null;
  ipAddresses: string[] | null;
  otherDomains: string[] | null;
  excludedTargets: string[] | null;
  securityScore: number | null;
  securityOverallScore: number | null;
  createdAt: Date;
  updatedAt: Date;
  subDomainScans: SecurityScanDTO[] | null;
  previousScan: SecurityScanDTO | null;
  benchmark: Benchmark | null;
}

export interface SecurityScanHistoryDTO {
  message: string;
  securityScans: SecurityScanDTO[];
  securityScansCount: number;
}

export enum TargetType {
  DOMAIN = 'domain',
  SUB_DOMAIN = 'sub_domain',
  IP_ADDRESS = 'ip_address',
  DOMAIN_SUB_DOMAIN = 'domain_sub_domain',
}

export interface TargetDTO {
  type: TargetType;
  address: string;
  numberOfFindings: number;
  scanningStatus: SecurityScanStatus;
}

export interface CreateTargetDTO {
  address: string;
}

export interface ApplicationDocumentsGroupDTO {
  id: string;
  name: string;
  createdAt: string;
  documents: ApplicationDocumentDTO[];
}

export interface AssignDocumentDTO {
  groupId: string | null;
}
